<template>
  <div
    :class="{ 'not-scrolled': !scrolled }"
    class="switch-container"
  >
    <label class="switch">
      <input
        type="checkbox"
        :checked="isLightMode"
        @click="changeTheme()"
      >
      <span class="slider" />
    </label>
  </div>
</template>

<script>
import { Themes } from "../app.constants";
import LocalStoregeService from "../services/localStorageService";

export default {
  name: "ThemeChangerComponent",
  props: {
    scrolled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isLightMode: LocalStoregeService.getTheme() === Themes.light,
    };
  },
  methods: {
    changeTheme() {
      LocalStoregeService.changeTheme();
      console.log(LocalStoregeService.getTheme());
    },
  },
};
</script>

<style scoped>
.switch-container {
  width: 100px;
}

.switch {
  font-size: 10px;
  position: relative;
  display: inline-block;
  width: 4.4em;
  height: 2em;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--accent);
  transition: var(--transition-speed);
  border-radius: 30px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.4em;
  width: 1.4em;
  border-radius: 50%;
  left: 10%;
  bottom: 15%;
  box-shadow: inset 8px -4px 0px 0px var(--background);
  background: var(--accent);
  transition: var(--transition-speed);
}

input:checked + .slider {
  background-color: var(--accent);
}

input:checked + .slider:before {
  transform: translateX(155%);
  box-shadow: inset 15px -4px 0px 15px var(--background);
}

.not-scrolled input:checked + .slider {
  background-color: #fdd187;
}

.not-scrolled input:checked + .slider:before {
  box-shadow: inset 15px -4px 0px 15px #323232;
}
</style>
