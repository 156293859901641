<template>
  <div class="services-container">
    <h2 class="sub-title">
      {{ $t("content.services.title") }}
    </h2>
    <div class="service service-left">
      <div class="service-image-container">
        <img
          class="service-image"
          src="../assets/images/phone.png"
          alt="phone image"
        />
      </div>
      <div class="service-content">
        <h3 class="service-name">
          {{ $t("content.services.mobile-apps-title") }}
        </h3>
        <p>{{ $t("content.services.mobile-apps-content-1") }}</p>
      </div>
    </div>

    <div class="service service-right">
      <div class="service-image-container">
        <img
          class="service-image"
          src="../assets/images/ecomerce.png"
          alt="phone image"
        />
      </div>
      <div class="service-content">
        <h3 class="service-name">
          {{ $t("content.services.ecomerce-title") }}
        </h3>
        <p>{{ $t("content.services.ecomerce-content-1") }}</p>
      </div>
    </div>

    <div class="service service-left">
      <div class="service-image-container">
        <img
          class="service-image"
          src="../assets/images/admin.png"
          alt="phone image"
        />
      </div>
      <div class="service-content">
        <h3 class="service-name">
          {{ $t("content.services.admin-title") }}
        </h3>
        <p>{{ $t("content.services.admin-content-1") }}</p>
      </div>
    </div>

    <div class="service service-right">
      <div class="service-image-container">
        <img
          class="service-image"
          src="../assets/images/presentation.png"
          alt="phone image"
        />
      </div>
      <div class="service-content">
        <h3 class="service-name">
          {{ $t("content.services.presentation-title") }}
        </h3>
        <p>{{ $t("content.services.presentation-content-1") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServicesComponent",
};
</script>

<style>
.services-container {
  box-sizing: border-box;
  margin-top: 4rem;
}

.service-image-container {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.service-image {
  width: 60%;
  border-radius: 15px;
}

.service-content {
  flex-grow: 1;
  width: 50%;
  text-align: justify;
}

.service {
  display: flex;
  gap: 1rem;
  margin-top: 4rem;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  padding: 40px;
}

.service-left {
  flex-direction: row;
}

.service-right {
  flex-direction: row-reverse;
}

.service-name {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.sub-title {
  text-align: center;
  margin-bottom: 1.5rem;
}

@media only screen and (max-width: 900px) {
  .sub-title {
    font-size: 1.5rem;
  }

  .service-name h3 {
    font-size: 1.2rem;
  }

  .service {
    padding: 20px;
  }

  .service,
  .services-container {
    margin-top: 2rem;
    align-items: center;
    flex-direction: column;
  }

  .service-content {
    width: 100%;
    text-align: justify;
  }

  .service-image-container {
    width: 90%;
  }

  .service-image {
    width: 100%;
  }
}
</style>
