<template>
  <button
    :class="{ disabled: disabled }"
    :disabled="disabled"
    @click="$emit('clicked')"
  >
    <span :class="{ small: small }">{{ buttonText }}</span>
  </button>
</template>

<script>
export default {
  name: "ButtonComponent",
  props: {
    buttonText: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["clicked"],
};
</script>

<style scoped>
button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: var(--button);
  box-shadow: var(--shadow);
  overflow: hidden;
  border: none;
}

button:after {
  content: " ";
  width: 0%;
  height: 100%;
  background: var(--background);
  position: absolute;
  transition: all var(--transition-speed) ease-in-out;
  right: 0;
}

button:hover::after {
  right: auto;
  left: 0;
  width: 100%;
}

button span {
  text-align: center;
  text-decoration: none;
  width: 8rem;
  padding: 12px 16px;
  color: var(--button-text);
  font-weight: 700;
  font-size: 12px;
  white-space: nowrap;
  letter-spacing: 0.3em;
  z-index: 20;
  transition: all var(--transition-speed) ease-in-out;
}

button span.small {
  padding: 6px 12px;
}

button:hover span {
  color: var(--text);
  animation: scaleUp 0.3s ease-in-out;
}

.disabled {
  background-color: var(--background);
}
.disabled span {
  color: var(--text);
}

@media only screen and (max-width: 900px) {
  button span {
    font-size: 12px;
    padding: 10px 16px;
    width: 10rem;
  }
}

@keyframes scaleUp {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}
</style>
