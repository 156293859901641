<template>
  <component :is="'style'">
    :root { --background: {{ background }}; --text: {{ text }}; --button-text:
    {{ buttonText }}; --button: {{ button }}; --accent: {{ accent }}; --accent2:
    {{ accent2 }}; --transition-speed: {{ transitionSpeed }}; --shadow:
    {{ shadow }}; --radius: {{ radius }}; --text-inverted: {{ textInverted }}
    } * { transition-duration: var(--transition-speed); color: var(--text) }
  </component>
</template>

<script>
import LocalStoregeService from "../services/localStorageService";
import { LightTheme, DarkTheme, Themes } from "../app.constants";

export default {
  name: "DynamicStyleComponent",
  data() {
    return {
      background: LightTheme.background,
      text: LightTheme.text,
      textInverted: DarkTheme.text,
      button: LightTheme.button,
      accent: LightTheme.accent,
      accent2: LightTheme.accent2,
      transitionSpeed: ".3s",
      shadow: "0px 8px 16px -8px black",
      buttonText: DarkTheme.text,
      radius: "8px",
    };
  },
  mounted() {
    this.changeTheme();

    window.addEventListener("theme-changed", this.changeTheme);
  },
  methods: {
    changeTheme() {
      if (LocalStoregeService.getTheme() === Themes.light) {
        this.text = LightTheme.text;
        this.textInverted = DarkTheme.text;
        this.button = LightTheme.button;
        this.accent = LightTheme.accent;
        this.accent2 = LightTheme.accent2;
        this.background = LightTheme.background;
      } else {
        this.text = DarkTheme.text;
        this.textInverted = LightTheme.text;
        this.button = DarkTheme.button;
        this.accent = DarkTheme.accent;
        this.accent2 = DarkTheme.accent2;
        this.background = DarkTheme.background;
      }
    },
  },
};
</script>
