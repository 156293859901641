<template>
  <div class="footer-container">
    <p class="copyright">
      © 2023 PotterTech. All rigths reserved.
    </p>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>

<style>
.footer-container {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--accent);
}

.copyright {
  font-size: small;
  color: var(--text-inverted);
  padding: 1rem;
  margin: 0;
}
</style>
