<template>
  <button
    class="tech-card"
    @click="$emit('clicked')"
  >
    <span>
      <img
        class="tech-logo"
        :src="image"
        :alt="buttonText"
      >
      {{ buttonText }}
    </span>
  </button>
</template>

<script>
export default {
  name: "TechCardComponent",
  props: {
    buttonText: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
  },
  emits: ["clicked"],
};
</script>

<style scoped>
button {
  position: relative;
  display: flex;
  justify-content: left;
  align-items: center;
  border-radius: 50px;
  background: var(--button);
  box-shadow: var(--shadow);
  overflow: hidden;
  border: none;
}

button:after {
  content: " ";
  width: 0%;
  height: 100%;
  background: var(--button);
  position: absolute;
  transition: all var(--transition-speed) ease-in-out;
  right: 0;
}

button:hover::after {
  right: auto;
  left: 0;
  width: 100%;
}

button span {
  text-align: left;
  text-decoration: none;
  width: 100%;
  color: var(--text);
  z-index: 20;
  transition: all var(--transition-speed) ease-in-out;
}

button:hover span {
  color: var(--button-text);
  animation: scaleUp 0.3s ease-in-out;
}

.tech-logo {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.4rem;
}

.stack-grid .tech-card {
  width: 210px;
  background-color: var(--background);
  box-shadow: var(--shadow);
  border-radius: var(--radius);
  padding: 20px;
}

.stack-grid .tech-card:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.3) 0px 3px 6px;
  transform: scale(0.96) translateY(-0.2rem);
}

@media only screen and (max-width: 900px) {
  .stack-grid .tech-card {
    width: 156px;
  }
}

@keyframes scaleUp {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}
</style>
