<template>
  <div class="tech-stack-container">
    <h2 class="sub-title">
      {{ $t("content.techStack.title") }}
    </h2>

    <div class="stack-grid">
      <TechCardComponent
        v-for="element in stack"
        :key="element.name"
        :button-text="element.name"
        :image="element.image"
        @clicked="goToLink(element.link)"
      />
    </div>
  </div>
</template>

<script>
import { TechStack } from "../app.constants";
import TechCardComponent from "./TechCardComponent.vue";

export default {
  name: "TechStackComponent",
  components: {
    TechCardComponent,
  },
  data() {
    return {
      stack: TechStack,
    };
  },
  methods: {
    goToLink(link) {
      window.open(link, "_blank");
    },
  },
};
</script>

<style scoped>
.tech-stack-container {
  box-sizing: border-box;
  margin-top: 4rem;
}

.sub-title {
  text-align: center;
}

.stack-grid {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.5rem;
}

@media only screen and (max-width: 900px) {
  .sub-title {
    font-size: 1.5rem;
  }

  .tech-stack-container {
    margin-top: 2rem;
  }
}
</style>
