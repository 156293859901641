<template>
  <div class="p-0 h-100 w-100">
    <DynamicStyleComponent />
    <NavbarComponent />
    <ContentComponent />
    <FooterComponent />
    <FloatingChatComponent />
  </div>
</template>

<script>
import NavbarComponent from "../components/NavbarComponent.vue";
import ContentComponent from "../components/ContentComponent.vue";
import FooterComponent from "../components/Footer.vue";
import FloatingChatComponent from "../components/FloatingChat.vue";
import DynamicStyleComponent from "../components/DynamicStyleComponent.vue";

export default {
  name: "HomePage",
  components: {
    NavbarComponent,
    ContentComponent,
    FooterComponent,
    FloatingChatComponent,
    DynamicStyleComponent,
  },
};
</script>
