<template>
  <div
    :class="{ 'not-scrolled': !scrolled }"
    class="locale-changer"
  >
    <button
      :class="{ active: $i18n.locale == 'ro' }"
      @click="changeLanguage('ro')"
    >
      RO
    </button>
    |
    <button
      :class="{ active: $i18n.locale == 'en' }"
      @click="changeLanguage('en')"
    >
      EN
    </button>
  </div>
</template>

<script>
import LocalStoregeService from "../services/localStorageService";

export default {
  name: "LanguageChangerComponent",
  props: {
    scrolled: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.$root.$i18n.locale = LocalStoregeService.getLanguage();
  },
  methods: {
    changeLanguage(code) {
      this.$root.$i18n.locale = LocalStoregeService.setLanguage(code);
    },
  },
};
</script>

<style scoped>
.locale-changer {
  width: 100px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.locale-changer,
.locale-changer button {
  background: none;
  color: var(--text);
  border: none;
  font-weight: 900;
}

.active {
  color: var(--accent) !important;
}

.not-scrolled .active {
  color: #fdd187 !important;
}

.not-scrolled,
.not-scrolled button {
  color: var(--button-text);
}
</style>
