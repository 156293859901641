<template>
  <div
    id="navbar"
    class="navbar"
    :class="{ 'navbar-scrolled': scrolled }"
  >
    <ThemeChangerComponent :scrolled="scrolled" />
    <LogoComponent :scrolled="scrolled" />
    <LanguageChangerComponent :scrolled="scrolled" />
  </div>
</template>

<script>
import LanguageChangerComponent from "@/components/LanguageChangerComponent.vue";
import ThemeChangerComponent from "@/components/ThemeChangerComponent.vue";
import LogoComponent from "@/components/LogoComponent.vue";

export default {
  name: "NavbarComponent",
  components: {
    LanguageChangerComponent,
    ThemeChangerComponent,
    LogoComponent,
  },
  data() {
    return {
      scrolled: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollChecker);
  },
  methods: {
    scrollChecker() {
      this.scrolled =
        document.body.scrollTop > 48 || document.documentElement.scrollTop > 48
          ? true
          : false;
    },
  },
};
</script>

<style scoped>
.navbar {
  height: 120px;
  width: 90%;
  position: sticky;
  transition: 0.8s;
  top: 0px;
  z-index: 99;
  box-sizing: border-box;
  padding: 8px;
  max-width: 1200px;
  margin: auto;
  flex-wrap: nowrap;
}

.navbar-scrolled {
  transition: all 200ms linear;
  height: 72px;
  background: var(--background);
  box-shadow: var(--shadow);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
</style>
