<script>
import ButtonComponent from "./ButtonComponent.vue";
import InputFieldComponent from "./InputFieldComponent.vue";
import { validateEmail } from "../app.constants";

export default {
  name: "ReferralModalComponent",
  components: {
    ButtonComponent,
    InputFieldComponent,
  },
  props: {
    show: Boolean,
  },
  emits: ["close"],
  data() {
    return {
      code: "",
      showCode: false,
      email: "",
    };
  },
  methods: {
    async getCode() {
      if (this.email.length === 0) {
        this.$notify({
          title: this.$t("notifications.fillEmail"),
          type: "error",
        });
        return;
      }

      if (!validateEmail(this.email)) {
        this.$notify({
          title: this.$t("notifications.invalidEmail"),
          type: "error",
        });
        return;
      }

      try {
        const respose = await this.axios.post(
          `${process.env.VUE_APP_API_URL}referral`,
          {
            email: this.email,
          }
        );
        this.code = respose.data;
        this.showCode = true;
        this.email = "";
      } catch (error) {
        const errorType = error.response.data;

        if (errorType === "used_email") {
          this.$notify({
            title: this.$t("notifications.invalidEmail"),
            type: "error",
          });
          return;
        }

        this.$notify({
          title: this.$t("notifications.unexpectedError"),
          type: "error",
        });
      }
    },
    resetAndClose() {
      this.showCode = false;
      this.code = "";
      this.$emit("close");
    },
  },
};
</script>

<template>
  <Transition name="modal">
    <div
      v-if="show"
      class="modal-mask"
    >
      <div class="modal-container">
        <h3>{{ $t("referralModal.title") }}</h3>
        <p>{{ $t("referralModal.descriptionGet") }}</p>
        <p>{{ $t("referralModal.descriptionOffer") }}</p>
        <p>{{ $t("referralModal.descriptionReward") }}</p>
        <InputFieldComponent
          v-if="!showCode"
          v-model="email"
          :placeholder="$t('referralModal.emailPlaceholder')"
        />
        <div
          v-if="showCode"
          class="codeBox"
        >
          {{ code }}
        </div>
        <ButtonComponent
          class="button"
          :disabled="showCode"
          :button-text="$t('referralModal.getButton')"
          :small="true"
          @clicked="getCode()"
        />
        <ButtonComponent
          class="button"
          :button-text="$t('referralModal.closeButton')"
          :small="true"
          @clicked="resetAndClose()"
        />
      </div>
    </div>
  </Transition>
</template>

<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  transition: opacity var(--transition-speed) ease;
}

.codeBox {
  background-color: #353535;
  border-radius: var(--radius) var(--radius) 0 0;
  height: 36px;
  padding: 12px;
  color: #e8e8e8;
  font-size: 0.9rem;
  margin-right: -4px;
  margin-left: 4px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid #fdd187;
  box-shadow: rgba(0, 0, 0, 0.7) 0px 2px 3px -1px inset;
}

.modal-container {
  width: 32rem;
  max-width: 90%;
  margin: auto;
  padding: 20px 30px;
  background-color: #323232;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  transition: all var(--transition-speed) ease;
}

.button {
  margin: 1rem auto;
}

.modal-container p {
  text-align: justify;
  box-sizing: border-box;
  color: #fbf5f3;
  padding: 0rem 0.3rem;
}

.modal-container h3 {
  text-align: center;
  margin-bottom: 2rem;
  color: #fbf5f3;
}

.modal-default-button {
  float: right;
}

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
