<template>
  <div>
    <a class="floating-button" href="https://wa.me/message/F4HH4TCSGQ3AN1">
      <img src="../assets/images/whatsapp.png" alt="whatsapp" />
    </a>
  </div>
</template>

<script>
export default {
  name: "FloatingChat",
  data() {
    return {
      showChat: false,
    };
  },
  methods: {
    showOrHideChat: function () {
      this.showChat = !this.showChat;
    },
  },
};
</script>

<style>
.floating-button {
  display: block;
  position: fixed;
  bottom: 28px;
  right: 28px;
  height: 56px;
  width: 56px;
  z-index: 1000;
  border-radius: 50%;
  background-color: var(--color1);
  border: none;
  box-shadow: 0px 6px 10px -4px var(--color1);
  transition-duration: 0.5s;
}

.floating-button img {
  width: 3rem;
}

.chat-btn-active {
  border-radius: 25% 4px 25% 4px;
  background-color: var(--color4);
}

.floating-button svg {
  fill: white;
  transition-duration: 0.5s;
}

.show-svg {
  opacity: 0;
}

#button-first-envelope {
  fill: var(--color3);
}

#button-second-envelope {
  fill: var(--color2);
}

#button-circle-1 {
  fill: var(--color1);
  animation: floating-2 2s infinite linear;
}

#button-circle-2 {
  fill: var(--color1);
  animation: floating 2s infinite ease-in-out;
}

#button-circle-3 {
  fill: rgb(82, 53, 0);
  animation: floating-2 2s infinite linear;
}

#exit-svg {
  margin-top: -80px;
}

.dialog-center-point {
  display: block;
  position: fixed;
  width: 0px;
  height: 0px;
  top: 50%;
  right: 50%;
  z-index: 0;
  background-color: black;
}

.dialog-center-point .dialog-content {
  display: block;
  opacity: 0;
  position: relative;
  height: 300px;
  width: 300px;
  margin-left: -150px;
  margin-top: -150px;
  background-color: var(--color2);
  box-shadow: 0px 6px 16px -4px var(--color1);
  padding: 24px;
  box-sizing: border-box;
  border-radius: 4px;
  transform: translateY(20px);
  transition: 0.5s all ease;
}

.show-dialog {
  z-index: 2;
}

.dialog-center-point .show-dialog {
  border-radius: 20px 4px 20px 4px;
  transform: translateY(0px);
  opacity: 1;
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  75% {
    transform: translate(0, 4px);
  }
  25% {
    transform: translate(0, -4px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

@keyframes floating-2 {
  0% {
    transform: translate(0, 0px);
  }
  25% {
    transform: translate(0, 4px);
  }
  75% {
    transform: translate(0, -4px);
  }
  100% {
    transform: translate(0, -0px);
  }
}
</style>
