export const DarkTheme = {
  background: "#323232",
  text: "#FBF5F3",
  button: "#AF9908",
  accent: "#FDD187",
  accent2: "#7A7256",
};

export const LightTheme = {
  background: "#FBF5F3",
  text: "#323232",
  button: "#AF9908",
  accent: "#7A7256",
  accent2: "#FDD187",
};

export const Themes = {
  light: "light",
  dark: "dark",
};

export const TechStack = [
  {
    name: "VueJS",
    image: require("./assets/images/vue.svg"),
    link: "https://vuejs.org/",
  },
  {
    name: "ReactJS",
    image: require("./assets/images/react.svg"),
    link: "https://reactjs.org/",
  },
  {
    name: "Quasar",
    image: require("./assets/images/qs.svg"),
    link: "https://quasar.dev/",
  },
  {
    name: "Flutter",
    image: require("./assets/images/flutter.svg"),
    link: "https://cordova.apache.org/",
  },
  {
    name: "NestJS",
    image: require("./assets/images/nest.svg"),
    link: "https://nestjs.com/",
  },
  {
    name: "TypeScript",
    image: require("./assets/images/ts.svg"),
    link: "https://www.typescriptlang.org/",
  },
  {
    name: "Laravel",
    image: require("./assets/images/lara.svg"),
    link: "https://laravel.com/",
  },
  {
    name: "CodeIgniter",
    image: require("./assets/images/ci.svg"),
    link: "https://codeigniter.com/",
  },
  {
    name: "WordPress",
    image: require("./assets/images/wp.svg"),
    link: "https://wordpress.com/",
  },
  {
    name: "MySQL",
    image: require("./assets/images/mysql.svg"),
    link: "https://www.mysql.com/",
  },
];

export function validateEmail(email) {
  if (email.match(/\S+@\S+\.\S+/)) return true;

  return false;
}
