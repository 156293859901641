<template>
  <HomePageVue />
  <notifications class="notification" position="top center" />
</template>

<script>
import HomePageVue from "./pages/HomePage.vue";

export default {
  name: "App",
  components: {
    HomePageVue,
  },
};
</script>

<style>
@font-face {
  font-family: "myFontLight";
  src: url("./assets/fonts/Roboto-Light.ttf");
}

@font-face {
  font-family: "myFontBold";
  src: url("./assets/fonts/Roboto-Bold.ttf");
}

#app {
  font-family: "myFontLight";
  background-color: var(--background);
  height: 100%;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: var(--background);
}

::-webkit-scrollbar-thumb {
  border-radius: 4px 0px 0px 4px;
  background: var(--accent);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--button);
}

.notification {
  z-index: 10000 !important;
  margin-top: 1rem;
  border-radius: var(--radius);
}

.notification .vue-notification .notification-title {
  color: #fbf5f3 !important;
}
</style>
