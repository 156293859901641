import { createApp } from "vue";
import App from "./App.vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { createI18n } from "vue-i18n";
import Notifications from "@kyvg/vue3-notification";
import en from "./assets/i18n/en.json";
import ro from "./assets/i18n/ro.json";

const i18n = createI18n({
  locale: "ro",
  fallbackLocale: "en",
  messages: { ro, en },
});

const app = createApp(App);

app.use(i18n);
app.use(Notifications);
app.use(VueAxios, axios);
app.mount("#app");
