<template>
  <div class="qualities-container">
    <h2 class="sub-title">
      {{ $t("content.qualities.title") }}
    </h2>
    <div class="qualities-grid">
      <div class="quality">
        <img
          class="quality-img"
          src="../assets/images/secure-shield.png"
          :alt="$t('content.qualities.trustworthy.description')"
        >
        <h3>
          {{ $t("content.qualities.trustworthy.title") }}
        </h3>
        <p class="description">
          {{ $t("content.qualities.trustworthy.description") }}
        </p>
      </div>

      <div class="quality">
        <img
          class="quality-img"
          src="../assets/images/learning.png"
          :alt="$t('content.qualities.experience.title')"
        >
        <h3>
          {{ $t("content.qualities.experience.title") }}
        </h3>
        <p class="description">
          {{ $t("content.qualities.experience.description") }}
        </p>
      </div>

      <div class="quality">
        <img
          class="quality-img"
          src="../assets/images/leader.png"
          :alt="$t('content.qualities.unique.title')"
        >
        <h3>
          {{ $t("content.qualities.unique.title") }}
        </h3>
        <p class="description">
          {{ $t("content.qualities.unique.description") }}
        </p>
      </div>

      <div class="quality">
        <img
          class="quality-img"
          src="../assets/images/directions.png"
          :alt="$t('content.qualities.flexible.title')"
        >
        <h3>
          {{ $t("content.qualities.flexible.title") }}
        </h3>
        <p class="description">
          {{ $t("content.qualities.flexible.description") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QualitiesComponent",
};
</script>

<style scoped>
.qualities-container {
  box-sizing: border-box;
  margin-top: 4rem;
}

.sub-title {
  text-align: center;
}

.qualities-grid {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.quality {
  width: 48%;
  padding: 20px;
  min-height: 17rem;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.quality:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.3) 0px 3px 6px;
  transform: scale(0.96) translateY(-0.2rem);
}

.quality-img {
  height: 3rem;
  margin-bottom: 0.3rem;
}

.quality h3 {
  font-size: 1.5rem;
}

.quality .description {
  text-align: justify;
}

@media only screen and (max-width: 900px) {
  .sub-title {
    font-size: 1.5rem;
  }

  .quality {
    width: 100%;
  }

  .quality h3 {
    font-size: 1.2rem;
  }
  .qualities-container {
    margin-top: 2rem;
  }
}
</style>
