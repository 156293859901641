import { Themes } from "../app.constants";

export default class LocalStorageService {
  static getLanguage() {
    const language = localStorage.getItem("language");

    return language ? language : "ro";
  }

  static setLanguage(code) {
    localStorage.setItem("language", code);

    return code;
  }

  static getTheme() {
    const theme = localStorage.getItem("theme");

    return theme ? theme : Themes.dark;
  }

  static changeTheme() {
    const currentTheme = this.getTheme();

    if (currentTheme === Themes.dark)
      localStorage.setItem("theme", Themes.light);
    else localStorage.setItem("theme", Themes.dark);

    window.dispatchEvent(new CustomEvent("theme-changed"));
  }
}
