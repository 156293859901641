<template>
  <GreetingComponent />

  <div class="content">
    <ServicesComponent class="reveal" />
    <QualitiesComponent class="reveal" />
    <TechStackComponent class="reveal" />
  </div>
</template>

<script>
import GreetingComponent from "./GreetingComponent.vue";
import TechStackComponent from "./TechStackComponent.vue";
import QualitiesComponent from "./QualitiesComponent.vue";
import ServicesComponent from "./ServicesComponent.vue";

export default {
  name: "ContentComponent",
  components: {
    GreetingComponent,
    TechStackComponent,
    QualitiesComponent,
    ServicesComponent,
  },
  mounted() {
    this.reveal();
    window.addEventListener("scroll", this.reveal);
  },
  methods: {
    reveal() {
      let reveals = document.querySelectorAll(".reveal");

      for (let i = 0; i < reveals.length; i++) {
        let windowHeight = window.innerHeight;
        let elementTop = reveals[i].getBoundingClientRect().top;
        let elementVisible = 30;

        if (elementTop <= windowHeight - elementVisible)
          reveals[i].classList.add("active");
      }
    },
  },
};
</script>

<style>
.reveal {
  position: relative;
  transform: translateY(20px);
  opacity: 0;
  transition: 1s all ease;
}

.reveal.active {
  transform: translateY(0);
  opacity: 1;
}

.content {
  height: 100%;
  width: 90%;
  max-width: 1200px;
  box-sizing: border-box;
  margin: auto;
  position: relative;
  z-index: 1;
}

.title {
  font-size: 2.5rem;
  letter-spacing: 2px;
  line-height: 1.1;
  margin: 0px;
}

.img-section {
  width: 100%;
  margin: 64px auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.section {
  display: flex;
  gap: 16px;
  justify-content: center;
  margin-bottom: 64px;
}

.section-img {
  width: 33%;
  display: flex;
  align-items: center;
}

.section-img svg {
  width: 100%;
}

.section-description {
  width: 70%;
  padding: 36px;
  height: 100%;
  margin: auto;
  border-radius: 12% 10px 15% 10px;
  background-color: var(--color3);
  box-shadow: 0px 6px 10px -4px var(--color1);
  transition: box-shadow 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.section-description:hover {
  box-shadow: 0px 6px 20px -6px var(--color2);
}

.section-text-1 {
  font-family: "myFontBold";
  text-transform: uppercase;
  font-size: 20px;
  color: var(--color4);
}

.section-text-2 {
  color: var(--color1);
  margin: 24px auto;
  font-size: 24px;
  font-weight: bold;
}

.section-text-3 {
  font-size: 16px;
  color: black;
}

.machine {
  fill: var(--color1);
}

.medium-shadow,
.large-shadow {
  fill: rgba(0, 0, 0, 0.15);
}

.medium-shadow {
  animation: rotation 3.75s infinite linear;
  transform-origin: 264.675px 389.447px;
}

.large-shadow {
  animation: counter-rotation 5s infinite linear;
  transform-origin: 471.37px 183.694px;
}

.medium {
  fill: var(--color2);
  animation: rotation 3.75s infinite linear;
  transform-origin: 254.675px 379.447px;
}

.large {
  animation: counter-rotation 5s infinite linear;
  transform-origin: 461.37px 173.694px;
}

.machine-writing {
  color: black;
}

.machine-large-text {
  font-size: 80px;
  font-weight: 700;
  fill: var(--color2);
}

.machine-medium-text {
  font-size: 50px;
  font-weight: 800;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes counter-rotation {
  from {
    transform: rotate(359deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@media only screen and (max-width: 900px) {
  .section-text-1 {
    text-align: center;
  }

  .section-text-2 {
    font-size: 20px;
    text-align: justify;
  }

  .section-text-3 {
    font-size: 16px;
    text-align: justify;
  }

  .section {
    margin-bottom: unset;
  }

  .img-section {
    gap: 24px;
  }
}

@media only screen and (max-width: 900px) {
  .content {
    width: 90%;
    margin: auto;
  }

  .main-text {
    margin: 48px auto;
    padding: 0px;
    width: 100%;
    text-align: center;
  }

  .service-description {
    padding: 12px;
  }

  .second-text {
    margin-top: 96px;
  }

  .services-container {
    flex-direction: column;
    margin: 36px auto;
  }

  .service-box {
    width: 100%;
    flex-direction: column;
    padding: 12px;
  }

  .service-title {
    text-align: center;
  }

  .section {
    flex-direction: column;
    margin-bottom: 46px;
  }

  .section-2 {
    flex-direction: column-reverse;
  }

  .section-img {
    margin: auto;
    width: 70%;
  }

  .section-description {
    margin: auto;
    width: 100%;
  }

  .img-section {
    margin-top: 0px;
  }
}
</style>
