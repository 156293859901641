<template>
  <ReferralModalComponent
    :show="showReferralModal"
    @close="showReferralModal = false"
  />
  <div class="container">
    <picture>
      <source
        media="(max-width: 900px)"
        srcset="../assets/images/greeting-1-1.png"
      />
      <source
        media="(min-width: 901px)"
        srcset="../assets/images/greeting-2-3.png"
      />
      <img class="workspace-image" alt="PotterTech - Workspace" />
    </picture>
    <div class="center-point">
      <div class="overlayer">
        <h1 class="greeting-title">
          {{ $t("content.title") }}
        </h1>
        <h4 class="contact-text">{{ $t("content.contactUs") }}</h4>
        <div class="buttons">
          <ButtonComponent
            :button-text="$t('content.buttons.getOffer')"
            class="center"
            @clicked="getOfferModal()"
          />
          <ButtonComponent
            :button-text="$t('content.buttons.getReferral')"
            class="center"
            @clicked="getReferralModal()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonComponent from "./ButtonComponent.vue";
import ReferralModalComponent from "./ReferralModalComponent.vue";

export default {
  name: "GreetingComponent",
  components: {
    ButtonComponent,
    ReferralModalComponent,
  },
  data() {
    return {
      showReferralModal: false,
    };
  },
  methods: {
    getOfferModal() {
      window.location.href = "https://wa.me/message/F4HH4TCSGQ3AN1";
    },
    getReferralModal() {
      window.location.href = "mailto:alexx.potter458@gmail.com";
    },
  },
};
</script>

<style scoped>
.container {
  position: relative;
  margin: unset;
  padding: unset;
  max-width: unset;
}

.center-point {
  position: absolute;
  display: flex;
  justify-content: center;
  top: calc(50% - 4rem);
  width: 100%;
}

.overlayer {
  width: 90%;
  box-sizing: border-box;
  padding: 0.75rem;
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  margin-top: -3.5rem;
}

.greeting-title {
  color: var(--button-text);
  text-align: center;
}

.overlayer .buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.75rem 4rem;
}

.workspace-image {
  width: 100%;
  height: 55vh;
  min-height: 33rem;
  object-fit: cover;
  object-position: bottom;
  filter: brightness(50%);
  margin-top: -7.5rem;
}

@media only screen and (max-width: 900px) {
  .greeting-title {
    font-size: 2rem;
  }

  .overlayer .buttons {
    gap: 1rem;
  }

  .center-point {
    top: calc(50% - 8rem);
  }

  .contact-text {
    font-size: 1.2rem;
  }

  .overlayer {
    margin-top: -2.5rem;
  }
}
</style>
